import React, { useState } from 'react';
import { Languages, ArrowLeft, CheckCircle, Rocket, Globe, BookOpen, MessageSquare, Headphones, Video, Users } from 'lucide-react';

const LanguageLearningPage = () => {
    const [darkMode, setDarkMode] = useState(false);

    const benefits = [
        {
            icon: Globe,
            title: "Interaktive Sprachübungen",
            description: "Moderne Apps und Plattformen bieten spielerische Übungen für Vokabeln, Grammatik und Aussprache."
        },
        {
            icon: MessageSquare,
            title: "Spracherkennung & Aussprache",
            description: "KI-gestützte Spracherkennung gibt sofortiges Feedback zur Aussprache und hilft bei der Verbesserung."
        },
        {
            icon: Headphones,
            title: "Audio & Podcasts",
            description: "Authentische Hörmaterialien in verschiedenen Schwierigkeitsgraden für besseres Hörverständnis."
        },
        {
            icon: Video,
            title: "Video-Content & Streaming",
            description: "Zugang zu Originalvideos, Filmen und Serien in der Zielsprache mit mehrsprachigen Untertiteln."
        },
        {
            icon: Users,
            title: "Sprach-Tandems",
            description: "Digitale Plattformen ermöglichen den direkten Austausch mit Muttersprachlern weltweit."
        },
        {
            icon: BookOpen,
            title: "Digitale Wörterbücher",
            description: "Schneller Zugriff auf Übersetzungen, Synonyme und Kontextbeispiele in verschiedenen Sprachen."
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Zurück-Button */}
            <button
                onClick={() => window.history.back()}
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-green-500 to-teal-500 text-white rounded-lg hover:from-green-600 hover:to-teal-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-green-500 to-teal-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-green-100 text-green-600'}`}>
                        <Languages className="h-5 w-5"/>
                        <span className="font-medium">Sprachen lernen neu gedacht</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-green-500 to-teal-500'} text-transparent bg-clip-text`}>
                            Fremdsprachen digital & interaktiv lernen
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Entdecken Sie, wie digitale Tools und Tablets den Fremdsprachenunterricht revolutionieren
                        und Schüler*innen motivieren, neue Sprachen zu entdecken.
                    </p>
                </div>
            </div>

            {/* Vorteile Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Vorteile des digitalen Sprachenlernens
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {benefits.map((benefit, idx) => (
                        <div key={idx}
                             className={`p-6 rounded-xl hover:shadow-xl transition-all transform hover:scale-105 
                             ${darkMode ? 'bg-gray-800 hover:bg-gray-750' : 'bg-white hover:bg-green-50'}`}>
                            <div className={`h-12 w-12 mx-auto rounded-lg flex items-center justify-center mb-4 
                                ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-green-500 to-teal-500'}`}>
                                <benefit.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Features Section */}
            <div className="max-w-7xl mx-auto px-4 py-16">
                <div className={`rounded-2xl p-8 ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl`}>
                    <h2 className={`text-3xl md:text-4xl font-bold mb-8 text-center ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        Sprachen lernen mit System
                    </h2>
                    <div className="grid md:grid-cols-2 gap-8 items-center">
                        <div className="space-y-4">
                            <div className={`flex items-start gap-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
                                <p>Individuelles Lerntempo durch adaptive Lernpfade</p>
                            </div>
                            <div className={`flex items-start gap-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
                                <p>Gamification-Elemente für höhere Motivation</p>
                            </div>
                            <div className={`flex items-start gap-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
                                <p>Echtzeit-Übersetzung und Kontextverständnis</p>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className={`flex items-start gap-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
                                <p>Kultureller Austausch durch virtuelle Klassenräume</p>
                            </div>
                            <div className={`flex items-start gap-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
                                <p>Fortschrittstracking und detaillierte Lernanalysen</p>
                            </div>
                            <div className={`flex items-start gap-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
                                <p>Integration authentischer Materialien aus dem Internet</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Bereit für moderne Sprachbildung?
                </h2>
                <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Digitale Tools eröffnen neue Wege des Sprachenlernens. Nutzen Sie diese Chance und
                    machen Sie Ihren Sprachunterricht interaktiver und effektiver.
                </p>
                <button className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-green-500 to-teal-500 text-white rounded-xl hover:from-green-600 hover:to-teal-600 transition-all transform hover:scale-105 shadow-lg">
                    Jetzt entdecken <Rocket className="h-5 w-5 ml-2"/>
                </button>
            </div>
        </div>
    );
};

export default LanguageLearningPage;