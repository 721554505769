import React, { useState } from 'react';
import { Brain, ArrowLeft, CheckCircle, Rocket, Search, BookOpen } from 'lucide-react';

const AIEducationPage = () => {
    const [darkMode, setDarkMode] = useState(false);

    const benefits = [
        {
            icon: Search,
            title: "Intelligente Rechercheunterstützung",
            description: "KI hilft Schüler*innen dabei, relevante Informationen schnell zu finden und zu bewerten."
        },
        {
            icon: BookOpen,
            title: "Personalisierte Lernhilfen",
            description: "KI-Systeme passen sich an das individuelle Lerntempo an und bieten maßgeschneiderte Erklärungen."
        },
        {
            icon: CheckCircle,
            title: "Automatisches Feedback",
            description: "Sofortige Rückmeldungen zu Aufgaben ermöglichen es Schüler*innen, aus Fehlern zu lernen."
        },
        {
            icon: CheckCircle,
            title: "Kreative Ideenfindung",
            description: "KI kann als Inspiration dienen und helfen, neue Perspektiven auf Themen zu entwickeln."
        },
        {
            icon: CheckCircle,
            title: "Sprachunterstützung",
            description: "KI-basierte Übersetzungen und Sprachassistenten fördern das mehrsprachige Lernen."
        },
        {
            icon: CheckCircle,
            title: "Projektmanagement",
            description: "KI hilft bei der Strukturierung von Projekten und der Organisation von Lernmaterialien."
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Zurück-Button */}
            <button
                onClick={() => window.history.back()}
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-blue-500 to-purple-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-blue-100 text-blue-600'}`}>
                        <Brain className="h-5 w-5"/>
                        <span className="font-medium">Die Zukunft des Lernens ist hier</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-blue-500 to-purple-500'} text-transparent bg-clip-text`}>
                            KI-Unterstützung im modernen Unterricht
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Entdecken Sie, wie Künstliche Intelligenz den Unterricht bereichert und Schüler*innen dabei hilft,
                        ihr volles Potenzial zu entfalten.
                    </p>
                </div>
            </div>

            {/* Vorteile Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Vorteile der KI-Integration im Unterricht
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {benefits.map((benefit, idx) => (
                        <div key={idx} className={`p-6 rounded-xl hover:shadow-xl transition-all ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                            <div className={`h-12 w-12 mx-auto rounded-lg flex items-center justify-center mb-4 ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-blue-500 to-purple-500'}`}>
                                <benefit.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Bereit für die KI-gestützte Bildungsrevolution?
                </h2>
                <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    KI bietet unzählige Möglichkeiten, den Unterricht interaktiver und effizienter zu gestalten.
                    Nutzen Sie diese Chance und bereiten Sie Ihre Schüler*innen optimal auf die digitale Zukunft vor.
                </p>
                <button className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-xl hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 shadow-lg">
                    Jetzt starten <Rocket className="h-5 w-5 ml-2"/>
                </button>
            </div>
        </div>
    );
};

export default AIEducationPage;