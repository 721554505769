import React, { useState } from 'react';
import { HelpCircle, Plus, Minus, Sparkles, MessagesSquare } from 'lucide-react';

const FAQSection = ({ darkMode }) => {
    const [openQuestion, setOpenQuestion] = useState(null);

    const faqs = [
        {
            question: "Wie beginne ich am besten mit dem Tablet-Einsatz im Unterricht?",
            answer: "Der beste Start ist es, mit kleinen, überschaubaren Projekten zu beginnen. Fangen Sie zum Beispiel mit einer digitalen Mindmap oder einem Quiz an. Nutzen Sie die bereitgestellten Beispiele für Ihr Fach als Inspiration und erweitern Sie den Einsatz schrittweise."
        },
        {
            question: "Welche technischen Voraussetzungen benötigt meine Schule?",
            answer: "Die Grundvoraussetzungen sind ein stabiles WLAN-Netzwerk und die Tablets selbst. Wichtig ist auch ein Mobile Device Management System zur Verwaltung der Geräte. Die genauen Anforderungen können je nach gewünschtem Einsatzbereich variieren - sprechen Sie am besten mit Ihrer IT-Abteilung oder dem Schulträger."
        },
        {
            question: "Wie kann ich sicherstellen, dass die Tablets nicht vom Unterricht ablenken?",
            answer: "Klare Regeln und Strukturen sind wichtig. Etablieren Sie Nutzungsrichtlinien, definieren Sie präzise Arbeitsaufträge und nutzen Sie Classroom-Management-Tools. Die Tablets sollten als Werkzeug für spezifische Lernaktivitäten eingesetzt werden, nicht als dauerhafter Ersatz für traditionelle Methoden."
        },
        {
            question: "Was mache ich, wenn nicht alle Schüler ein Tablet haben?",
            answer: "Es gibt verschiedene Lösungsansätze: Partnerarbeit organisieren, Leihgeräte der Schule nutzen oder hybride Unterrichtskonzepte entwickeln. Viele Schulen arbeiten auch mit BYOD (Bring Your Own Device) oder haben Ausleihsysteme etabliert."
        },
        {
            question: "Wie bewerte ich digital erstellte Schülerarbeiten?",
            answer: "Digitale Arbeiten können Sie nach den gleichen Kriterien bewerten wie analoge. Zusätzlich können Sie die erweiterten Möglichkeiten (Multimedia, Interaktivität) in die Bewertung einbeziehen. Nutzen Sie digitale Bewertungstools für unmittelbares Feedback und dokumentieren Sie den Lernfortschritt."
        },
        {
            question: "Wie gehe ich mit technischen Problemen während des Unterrichts um?",
            answer: "Haben Sie immer einen Plan B bereit und bleiben Sie gelassen. Nutzen Sie technische Probleme als Lerngelegenheit für Problemlösungskompetenz. Erstellen Sie eine Liste häufiger Probleme und deren Lösungen, die Sie und Ihre Schüler schnell nachschlagen können."
        },
        {
            question: "Wie gehe ich mit Verstößen gegen die Tablet-Nutzungsrichtlinien um?",
            answer: "Definieren Sie klare Konsequenzen für Regelverstöße und kommunizieren Sie diese deutlich an die Schüler. Bei wiederholtem Fehlverhalten könnte beispielsweise die temporäre Einschränkung der Tablet-Nutzung sinnvoll sein. Wichtig ist auch eine offene Kommunikation mit den Eltern und die Einbindung in ein gemeinsames Regelwerk."
        },
    ];

    return (
        <div className="max-w-7xl mx-auto px-4 py-16">
            {/* FAQ Header */}
            <div className="text-center space-y-4 mb-12">
                <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-orange-100 text-orange-600'}`}>
                    <MessagesSquare className="h-5 w-5" />
                    <span className="font-medium">Häufig gestellte Fragen</span>
                </div>
                <h2 className={`text-3xl md:text-4xl font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-orange-500 to-red-500'} text-transparent bg-clip-text`}>
                        Ihre Fragen, unsere Antworten
                    </span>
                </h2>
                <p className={`text-xl max-w-2xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Finden Sie Antworten auf die wichtigsten Fragen zum Einsatz von Tablets im Unterricht
                </p>
            </div>

            {/* FAQ Items */}
            <div className="max-w-3xl mx-auto space-y-4">
                {faqs.map((faq, index) => (
                    <div
                        key={index}
                        className={`rounded-xl overflow-hidden transition-all duration-300
                            ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-orange-50'}
                            ${openQuestion === index ? 'shadow-xl' : 'shadow-lg'}`}
                    >
                        <button
                            onClick={() => setOpenQuestion(openQuestion === index ? null : index)}
                            className="w-full px-6 py-4 flex items-center justify-between gap-4"
                        >
                            <span className={`font-medium text-left ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                                {faq.question}
                            </span>
                            {openQuestion === index ? (
                                <Minus className={`flex-shrink-0 h-5 w-5 ${darkMode ? 'text-gray-400' : 'text-orange-500'}`} />
                            ) : (
                                <Plus className={`flex-shrink-0 h-5 w-5 ${darkMode ? 'text-gray-400' : 'text-orange-500'}`} />
                            )}
                        </button>
                        <div
                            className={`px-6 transition-all duration-300 ease-in-out overflow-hidden
                                ${openQuestion === index ? 'pb-4 max-h-96' : 'max-h-0'}`}
                        >
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                {faq.answer}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Help Section */}
            <div className={`mt-12 p-6 rounded-xl text-center ${darkMode ? 'bg-gray-800' : 'bg-orange-50'}`}>
                <HelpCircle className={`h-8 w-8 mx-auto mb-4 ${darkMode ? 'text-gray-400' : 'text-orange-500'}`} />
                <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Keine Antwort auf Ihre Frage gefunden?
                </p>
                <button className="mt-4 px-6 py-2 bg-gradient-to-r from-orange-500 to-red-500 text-white rounded-lg hover:from-orange-600 hover:to-red-600 transition-all transform hover:scale-105 shadow-md flex items-center gap-2 mx-auto">
                    <Sparkles className="h-4 w-4" />
                    <span>Kontaktieren Sie uns</span>
                </button>
            </div>
        </div>
    );
};

export default FAQSection;
