import React, { useState, useEffect } from 'react';
import { Sparkles, Tablet, BookOpen, Shield, Users, ChevronRight, Target, Star, Rocket, ArrowLeft } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';

const ModernMoreInfo = () => {
    const [darkMode, setDarkMode] = useState(false);
    const navigate = useNavigate();

    // Scrollt beim Laden der Seite nach oben
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const features = [
        {
            title: "KI-Unterstützung",
            icon: Sparkles,
            description: "KI kann im Unterricht eine wertvolle Hilfe sein, indem sie Schüler*innen bei der Recherche und beim Verstehen komplexer Themen unterstützt.",
            link: '/ki'
        },
        {
            title: "Fremdsprachen lernen",
            icon: BookOpen,
            description: "Tablets bieten Übersetzungs- und Sprachtools, die Schüler*innen im Fremdsprachenunterricht helfen können.",
            link: '/languages'
        },
        {
            title: "Medienkompetenz",
            icon: Target,
            description: "Tablets helfen Schüler*innen, den verantwortungsvollen Umgang mit digitalen Medien zu lernen.",
            link: '/media-literacy'
        },
        {
            title: "Individuelles Lernen",
            icon: Users,
            description: "Tablets ermöglichen es, den Unterricht auf verschiedene Lernniveaus anzupassen, sodass jede*r Schüler*in in ihrem/seinem eigenen Tempo lernen kann.",
            link: '/individual-learning'
        }
    ];

    const benefits = [
        {
            icon: Star,
            title: "Effizientere Organisation",
            description: "Digitale Kalender und Notizen für besseres Aufgabenmanagement."
        },
        {
            icon: Rocket,
            title: "Multimediales Lernen",
            description: "Zugriff auf Videos, interaktive Übungen und digitale Lehrbücher."
        },
        {
            icon: Shield,
            title: "Sichere Lernumgebung",
            description: "Spezielle Classroom-Management-Tools für fokussiertes Lernen."
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Zurück-Button in der oberen linken Ecke */}
            <button
                onClick={() => navigate(-1)}
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-orange-500 to-red-500 text-white rounded-lg hover:from-orange-600 hover:to-red-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section mit Animation */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-orange-500 to-red-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-orange-100 text-orange-600'}`}>
                        <Tablet className="h-5 w-5"/>
                        <span className="font-medium">Für Lehrkräfte: Tablets im Unterricht</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-orange-500 to-red-500'} text-transparent bg-clip-text`}>
                            Neue Wege des Lehrens mit digitalen Tools
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Erfahren Sie, wie der Einsatz von Tablets Ihren Unterricht bereichern und Ihre Schüler*innen bestmöglich auf die Herausforderungen der Zukunft vorbereiten kann.
                    </p>
                </div>
            </div>

            {/* Features Grid mit Link */}
            <div className="max-w-7xl mx-auto px-4 py-16">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-8">
                    {features.map((feature, idx) => (
                        <Link
                            to={feature.link}
                            key={idx}
                            className={`w-full max-w-xl mx-auto p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all transform hover:-translate-y-2 border-t-4 cursor-pointer ${darkMode ? 'bg-gray-800 text-gray-300 border-gray-700' : 'bg-white text-gray-900 border-orange-500'}`}
                        >
                            <div className={`h-16 w-16 rounded-xl flex items-center justify-center mb-6 transform -rotate-6 ${darkMode ? 'bg-gradient-to-br from-gray-700 to-gray-800' : 'bg-gradient-to-br from-orange-500 to-red-500'}`}>
                                <feature.icon className="h-8 w-8 text-white"/>
                            </div>
                            <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                            <p className={`mb-6 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{feature.description}</p>
                            <div className={`flex items-center font-medium ${darkMode ? 'text-gray-400' : 'text-orange-500'}`}>
                                <span>Mehr erfahren</span>
                                <ChevronRight className="h-5 w-5 ml-2"/>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            {/* Benefits Section */}
            <div className={`py-16 ${darkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
                <div className="max-w-7xl mx-auto px-4">
                    <h2 className={`text-3xl md:text-4xl font-bold text-center mb-12 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        Vorteile der Tablet-Nutzung
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {benefits.map((benefit, idx) => (
                            <div key={idx} className={`w-full max-w-md mx-auto p-6 rounded-xl hover:shadow-xl transition-all ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
                                <div className={`h-12 w-12 rounded-lg flex items-center justify-center mb-4 ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-orange-500 to-red-500'}`}>
                                    <benefit.icon className="h-6 w-6 text-white"/>
                                </div>
                                <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                                <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{benefit.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Call to Action */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Bereit für die digitale Transformation?
                </h2>
                <p className={`text-xl mb-8 max-w-2xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Entdecken Sie, wie Tablets Ihren Unterricht bereichern und Schüler*innen optimal auf die Zukunft vorbereiten können.
                </p>
                <Link
                    to="/transform"
                    className="w-1/2 px-8 py-4 bg-gradient-to-r from-orange-500 to-red-500 text-white rounded-xl hover:from-orange-600 hover:to-red-600 transition-all transform hover:scale-105 shadow-lg flex items-center justify-center gap-2 mx-auto"
                >
                    Jetzt starten <Rocket className="h-5 w-5"/>
                </Link>
            </div>
        </div>
    );
};

export default ModernMoreInfo;
