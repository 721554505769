import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importiere useNavigate
import { ArrowLeft, Code, School, Users, Heart, Sparkles } from 'lucide-react';

const AboutMePage = () => {
    const [darkMode, setDarkMode] = useState(false);
    const navigate = useNavigate(); // Initialisiere useNavigate

    const highlights = [
        {
            icon: Code,
            title: "Eigenentwicklung",
            description: "Diese Website habe ich selbst entwickelt und programmiert, um meine Vision des digitalen Lernens zu teilen."
        },
        {
            icon: School,
            title: "Schülerperspektive",
            description: "Als Schüler kenne ich die Herausforderungen und Chancen der Digitalisierung im Unterricht aus erster Hand."
        },
        {
            icon: Users,
            title: "Brückenbauer",
            description: "Ich möchte eine Brücke zwischen Lehrern, Eltern und Schülern bauen, um gemeinsam die digitale Transformation zu gestalten."
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Navigation Button */}
            <button
                onClick={() => navigate(-1)} // Verwende navigate, um zur vorherigen Seite zu gelangen
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-indigo-500 to-blue-500 text-white rounded-lg hover:from-indigo-600 hover:to-blue-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-indigo-500 to-blue-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
                        <Heart className={`h-5 w-5 ${darkMode ? 'text-blue-400' : 'text-blue-500'}`}/>
                        <span className={`font-medium ${darkMode ? 'text-blue-400' : 'text-blue-500'}`}>Mit Leidenschaft entwickelt</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-indigo-500 to-blue-500'} text-transparent bg-clip-text`}>
                            Über mich und dieses Projekt
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Als Schüler möchte ich einen Beitrag zur digitalen Transformation unserer Schulen leisten
                    </p>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto px-4 py-16">
                {/* Intro Card */}
                <div className={`relative mb-24 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-3xl shadow-xl overflow-hidden group`}>
                    <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-blue-500/10 transform group-hover:scale-105 transition-transform duration-700"></div>

                    {/* Decorative Elements */}
                    <div className="absolute -top-24 -right-24 w-48 h-48 bg-indigo-500/20 rounded-full blur-3xl"></div>
                    <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-blue-500/20 rounded-full blur-3xl"></div>

                    <div className="relative p-8 md:p-12">
                        {/* Profile Section */}
                        <div className="flex flex-col md:flex-row items-center gap-8 mb-12">
                            {/* Profile Image Placeholder */}
                            <div className={`w-32 h-32 rounded-full ${darkMode ? 'bg-gray-700' : 'bg-indigo-100'} flex items-center justify-center`}>
                                <School className={`w-16 h-16 ${darkMode ? 'text-gray-600' : 'text-indigo-500'}`} />
                            </div>

                            {/* Title Area */}
                            <div className="text-center md:text-left">
                                <div className={`inline-flex items-center gap-2 px-4 py-1 rounded-full text-sm ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-indigo-100 text-indigo-600'} mb-3`}>
                                    <Code className="w-4 h-4" />
                                    <span>Student Developer</span>
                                </div>
                                <h2 className={`text-2xl font-bold mb-2 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                                    Meine Vision
                                </h2>
                                <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                    Digitale Transformation im Bildungsbereich vorantreiben
                                </p>
                            </div>
                        </div>

                        {/* Main Text Content */}
                        <div className="max-w-4xl mx-auto">
                            <div className="space-y-12">
                                <div className="relative">
                                    <div className={`absolute left-0 w-1 h-full ${darkMode ? 'bg-gray-700' : 'bg-indigo-100'} rounded-full`}></div>
                                    <div className="ml-6">
                                        <div className={`absolute -left-3 top-0 w-6 h-6 rounded-full border-2 ${darkMode ? 'border-gray-700 bg-gray-800' : 'border-indigo-100 bg-white'}`}>
                                            <div className={`w-2 h-2 rounded-full ${darkMode ? 'bg-blue-400' : 'bg-indigo-500'} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}></div>
                                        </div>
                                        <p className={`text-lg leading-relaxed ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                            Als Schüler erlebe ich täglich, wie wichtig und wertvoll der Einsatz digitaler Medien im Unterricht sein kann. Gleichzeitig sehe ich auch die Herausforderungen, vor denen viele Lehrer bei der Integration von Tablets in ihren Unterricht stehen.
                                        </p>
                                    </div>
                                </div>

                                <div className="relative">
                                    <div className={`absolute left-0 w-1 h-full ${darkMode ? 'bg-gray-700' : 'bg-indigo-100'} rounded-full`}></div>
                                    <div className="ml-6">
                                        <div className={`absolute -left-3 top-0 w-6 h-6 rounded-full border-2 ${darkMode ? 'border-gray-700 bg-gray-800' : 'border-indigo-100 bg-white'}`}>
                                            <div className={`w-2 h-2 rounded-full ${darkMode ? 'bg-blue-400' : 'bg-indigo-500'} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}></div>
                                        </div>
                                        <p className={`text-lg leading-relaxed ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                            Diese Website habe ich entwickelt, um eine praktische Hilfestellung für Lehrer zu bieten, die den Einstieg in die Tabletnutzung suchen. Dabei war es mir wichtig, sowohl die Perspektive der Schüler als auch die Bedürfnisse der Lehrer zu berücksichtigen.
                                        </p>
                                    </div>
                                </div>

                                <div className="relative">
                                    <div className={`absolute left-0 w-1 h-full ${darkMode ? 'bg-gray-700' : 'bg-indigo-100'} rounded-full`}></div>
                                    <div className="ml-6">
                                        <div className={`absolute -left-3 top-0 w-6 h-6 rounded-full border-2 ${darkMode ? 'border-gray-700 bg-gray-800' : 'border-indigo-100 bg-white'}`}>
                                            <div className={`w-2 h-2 rounded-full ${darkMode ? 'bg-blue-400' : 'bg-indigo-500'} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}></div>
                                        </div>
                                        <p className={`text-lg leading-relaxed ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                            Mein Ziel ist es, eine Ressource zu schaffen, die konkrete Beispiele, praktische Tipps und Inspiration für den digitalen Unterricht bietet. Ich möchte zeigen, dass der Einsatz von Tablets nicht nur machbar ist, sondern auch neue, spannende Möglichkeiten für das Lernen eröffnet.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Highlights */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {highlights.map((highlight, idx) => (
                        <div key={idx} className={`p-6 rounded-xl hover:shadow-xl transition-all transform hover:scale-105 
                            ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-blue-50'}`}>
                            <div className={`h-12 w-12 rounded-lg flex items-center justify-center mb-4 
                                ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-indigo-500 to-blue-500'}`}>
                                <highlight.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{highlight.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{highlight.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call to Action */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Lass uns gemeinsam die Zukunft des Lernens gestalten
                </h2>
                <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Ich freue mich über Feedback, Anregungen und den Austausch mit Lehrern, Eltern und anderen Schülern.
                </p>
                <button className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-indigo-500 to-blue-500 text-white rounded-xl hover:from-indigo-600 hover:to-blue-600 transition-all transform hover:scale-105 shadow-lg">
                    Kontakt aufnehmen <Sparkles className="h-5 w-5 ml-2"/>
                </button>
            </div>

            {/* Dark Mode Toggle */}
            <button
                onClick={() => setDarkMode(!darkMode)}
                className="fixed bottom-4 right-4 p-3 rounded-full bg-gradient-to-r from-indigo-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-all"
            >
                {darkMode ? '☀️' : '🌙'}
            </button>
        </div>
    );
};

export default AboutMePage;
