import React from 'react';

const TermsOfService = () => {
    return (
        <div className="min-h-screen bg-gray-50 text-gray-900 px-4 py-12">
            <div className="max-w-3xl mx-auto space-y-8">
                <h1 className="text-4xl font-bold text-center">Allgemeine Geschäftsbedingungen (AGB)</h1>

                <section>
                    <h2 className="text-2xl font-semibold">1. Geltungsbereich</h2>
                    <p>
                        Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Geschäftsbeziehungen zwischen
                        [Ihr Unternehmen] und unseren Kunden in der jeweils gültigen Fassung zum Zeitpunkt des
                        Vertragsschlusses. Abweichende Vereinbarungen bedürfen der schriftlichen Bestätigung.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">2. Vertragsschluss</h2>
                    <p>
                        Die Darstellung der Produkte und Dienstleistungen auf unserer Website stellt kein rechtlich
                        bindendes Angebot dar, sondern eine Aufforderung zur Abgabe einer Bestellung. Mit Absenden der
                        Bestellung geben Sie ein verbindliches Angebot zum Abschluss eines Kaufvertrages ab.
                        Der Vertrag kommt erst durch unsere ausdrückliche Annahmeerklärung oder durch Lieferung der Ware
                        zustande.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">3. Preise und Zahlungsbedingungen</h2>
                    <p>
                        Alle angegebenen Preise sind Endpreise und enthalten die gesetzliche Mehrwertsteuer.
                        Versandkosten werden gesondert ausgewiesen. Die Bezahlung erfolgt je nach Wahl des Kunden per
                        Vorkasse, Kreditkarte, PayPal oder einer anderen auf unserer Website angebotenen Zahlungsmethode.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">4. Lieferbedingungen</h2>
                    <p>
                        Die Lieferung erfolgt an die vom Kunden angegebene Adresse. Angaben zur Lieferzeit sind
                        unverbindlich, soweit nicht ausnahmsweise der Liefertermin verbindlich zugesagt wurde.
                        Teillieferungen sind zulässig, sofern sie dem Kunden zumutbar sind.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">5. Widerrufsrecht</h2>
                    <p>
                        Verbrauchern steht ein Widerrufsrecht gemäß den gesetzlichen Bestimmungen zu. Nähere
                        Informationen zum Widerrufsrecht entnehmen Sie bitte unserer <a href="#widerrufsbelehrung" className="text-orange-500 hover:underline">Widerrufsbelehrung</a>.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">6. Eigentumsvorbehalt</h2>
                    <p>
                        Bis zur vollständigen Zahlung bleibt die Ware unser Eigentum. Vor Eigentumsübertragung ist
                        eine Verpfändung, Sicherungsübereignung, Verarbeitung oder Umgestaltung ohne unsere
                        Zustimmung nicht gestattet.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">7. Gewährleistung und Haftung</h2>
                    <p>
                        Es gelten die gesetzlichen Gewährleistungsrechte. Eine darüber hinausgehende Haftung ist
                        ausgeschlossen, sofern nicht zwingende gesetzliche Vorschriften eine weitergehende Haftung
                        vorsehen.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">8. Datenschutz</h2>
                    <p>
                        Informationen zum Datenschutz finden Sie in unserer <a href="/daten" className="text-orange-500 hover:underline">Datenschutzerklärung</a>.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">9. Schlussbestimmungen</h2>
                    <p>
                        Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam oder
                        undurchführbar sein oder nach Vertragsschluss unwirksam oder undurchführbar werden, bleibt
                        davon die Wirksamkeit des Vertrages im Übrigen unberührt. Es gilt deutsches Recht unter
                        Ausschluss des UN-Kaufrechts.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default TermsOfService;
