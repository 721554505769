import React, { useState } from 'react';
import { Tablet, Rocket, ArrowLeft, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const DigitalTransformationPage = () => {
    const [darkMode, setDarkMode] = useState(false);
    const navigate = useNavigate();

    const benefits = [
        {
            icon: CheckCircle,
            title: "Förderung der Medienkompetenz",
            description: "Schüler*innen lernen den verantwortungsvollen Umgang mit digitalen Medien, die in der modernen Welt unerlässlich sind."
        },
        {
            icon: CheckCircle,
            title: "Individualisiertes Lernen",
            description: "Tablets ermöglichen es, Lerninhalte und Aufgaben an das Niveau und Tempo jedes einzelnen Schülers anzupassen."
        },
        {
            icon: CheckCircle,
            title: "Interaktives und multimediales Lernen",
            description: "Zugriff auf Videos, Simulationen und Quizze steigert das Interesse und die Motivation der Schüler*innen."
        },
        {
            icon: CheckCircle,
            title: "Effizientes Aufgaben- und Projektmanagement",
            description: "Digitale Tools für Notizen und Kalender erleichtern es, den Überblick zu behalten und organisierter zu lernen."
        },
        {
            icon: CheckCircle,
            title: "Förderung von Teamarbeit und Kollaboration",
            description: "Tablets bieten Apps und Plattformen, auf denen Schüler*innen gemeinsam an Projekten arbeiten können."
        },
        {
            icon: CheckCircle,
            title: "Entwicklung von Recherchekompetenz",
            description: "Schüler*innen lernen den kritischen Umgang mit Informationen und Quellen – eine wichtige Fähigkeit für die Zukunft."
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Zurück-Button in der oberen linken Ecke */}
            <button
                onClick={() => navigate(-1)}
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-orange-500 to-red-500 text-white rounded-lg hover:from-orange-600 hover:to-red-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-orange-500 to-red-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-orange-100 text-orange-600'}`}>
                        <Tablet className="h-5 w-5"/>
                        <span className="font-medium">Bereit für die digitale Transformation?</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-orange-500 to-red-500'} text-transparent bg-clip-text`}>
                            Tablets im Unterricht für eine erfolgreiche Zukunft
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Entdecken Sie, wie der Einsatz von Tablets Ihre Schüler*innen optimal auf die Herausforderungen der modernen Welt vorbereitet und den Unterricht bereichert.
                    </p>
                </div>
            </div>

            {/* Vorteile Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Vorteile der digitalen Transformation im Unterricht
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {benefits.map((benefit, idx) => (
                        <div key={idx} className={`p-6 rounded-xl hover:shadow-xl transition-all ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                            <div className={`h-12 w-12 rounded-lg flex items-center justify-center mb-4 ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-orange-500 to-red-500'}`}>
                                <benefit.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Bereit, die Zukunft des Unterrichts zu gestalten?
                </h2>
                <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Tablets bieten eine Fülle von Möglichkeiten, um Schüler*innen auf die digitale Welt vorzubereiten. Nutzen Sie diese Chance und gestalten Sie Ihren Unterricht neu.
                </p>
                <a href="/kontakt" className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-orange-500 to-red-500 text-white rounded-xl hover:from-orange-600 hover:to-red-600 transition-all transform hover:scale-105 shadow-lg">
                    Kontakt aufnehmen <Rocket className="h-5 w-5 ml-2"/>
                </a>
            </div>
        </div>
    );
};

export default DigitalTransformationPage;
