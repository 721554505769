import React, { useState, useEffect } from 'react';
import FAQSection from './FAQSection';
import DownloadPage from './downlaodcenter';
import { Link } from 'react-router-dom';
import {
    Tablet,
    BookOpen,
    Users,
    Shield,
    Clock,
    Award,
    Menu,
    X,
    ChevronRight,
    Sparkles,
    Lightbulb,
    Target,
    Rocket,
    Star,
    Moon,
    Sun, Battery, Save, Download,
    } from 'lucide-react';



function TabletWebsite() {
    const [activeTab, setActiveTab] = useState('home');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => setDarkMode(!darkMode);

    const navigationItems = [
        {id: 'home', title: 'Home', icon: Tablet},
        {id: 'teachers', title: 'Für Lehrkräfte', icon: BookOpen},
        {id: 'students', title: 'Für Schüler', icon: Users},
        {id: 'parents', title: 'Für Eltern', icon: Shield},
        {id: 'faq', title: 'FAQ', icon: Lightbulb},
        {id: 'downloads', title: 'Downloads', icon: Download}
    ];
    const ModernMoreInfo = () => {
        const [darkMode, setDarkMode] = useState(false);
        const [wordIndex, setWordIndex] = useState(0);

        const tabletWords = ["Tablet", "Handy", "Media", "Laptop"];
        const tabletColors = ["bg-blue-500", "bg-green-500", "bg-yellow-500", "bg-red-500"];
        const teachingWords = ["Unterricht", "Arbeit", "Zukunft", "Schule", "Lernen"];

        useEffect(() => {
            const interval = setInterval(() => {
                setWordIndex((wordIndex + 1) % tabletWords.length);
            }, 2000);
            return () => clearInterval(interval);
        },[]);
    }

    const renderHome = () => (
        <div className="space-y-12 transition-all duration-300 ease-in-out">
            {/* Hero Section mit Animation */}
            <div className="relative overflow-hidden">
                <div
                    className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-orange-500 to-red-500'} opacity-10 transform -skew-y-6`}
                ></div>
                <div className="relative text-center space-y-8 py-20 px-4">
                    <div
                        className={`inline-flex items-center gap-2 px-4 py-2 rounded-full animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-orange-100 text-orange-600'}`}
                    >
                        <Sparkles className="h-5 w-5" />
                        <span className="font-medium">Entdecke die digitale Zukunft</span>
                    </div>
                    <h1 className={`text-5xl md:text-6xl lg:text-7xl font-bold tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    <span
                        className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-orange-500 to-red-500'} text-transparent bg-clip-text`}
                    >
                        Tablets
                    </span>{" "}
                        im Unterricht
                    </h1>
                    <p className={`text-xl max-w-2xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Gestalte deinen Unterricht interaktiv, modern und spannend
                    </p>
                    <div className="flex gap-4 justify-center">
                        <button onClick={() => setActiveTab('teachers')}
                                className="px-8 py-4 bg-gradient-to-r from-orange-500 to-red-500 text-white rounded-xl hover:from-orange-600 hover:to-red-600 transition-all transform hover:scale-105 shadow-lg flex items-center gap-2">
                            Jetzt durchstarten <Rocket className="h-5 w-5"/>
                        </button>
                    </div>
                </div>
            </div>

            {/* Feature Cards with Interactivity */}
            <div className="max-w-7xl mx-auto px-4 relative">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {[
                        {
                            icon: Lightbulb,
                            title: 'Innovative Lernmethoden',
                            desc: 'Entdecke neue Wege des Lernens mit interaktiven Übungen und multimedialen Inhalten.',
                            link: '/learning'
                        },
                        {
                            icon: Target,
                            title: 'Individuelles Lerntempo',
                            desc: 'Lerne in deinem eigenen Tempo und entwickle deine persönlichen Stärken.',
                            link: '/individual'
                        },
                        {
                            icon: Star,
                            title: 'Kreative Projektarbeit',
                            desc: 'Setze deine Ideen mit digitalen Tools um und gestalte spannende Projekte.',
                            link: '/creative'
                        }
                    ].map((feature, idx) => {
                        const CardContent = (
                            <>
                                <div
                                    className={`h-16 w-16 rounded-xl flex items-center justify-center mb-6 transform -rotate-6 ${darkMode ? 'bg-gradient-to-br from-gray-700 to-gray-800' : 'bg-gradient-to-br from-orange-500 to-red-500'}`}
                                >
                                    <feature.icon className="h-8 w-8 text-white" />
                                </div>
                                <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                                <p className="mb-6">
                                    {feature.desc}
                                </p>
                                <div
                                    className={`flex items-center font-medium ${darkMode ? 'text-gray-400' : 'text-orange-500'}`}
                                >
                                    <span>Mehr erfahren</span>
                                    <ChevronRight className="h-5 w-5 ml-2" />
                                </div>
                            </>
                        );

                        return feature.link ? (
                            <Link
                                to={feature.link}
                                key={idx}
                                className={`p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all transform hover:-translate-y-2 border-t-4 cursor-pointer ${darkMode ? 'bg-gray-800 text-gray-300 border-gray-700' : 'bg-white text-gray-900 border-orange-500'}`}
                            >
                                {CardContent}
                            </Link>
                        ) : (
                            <div
                                key={idx}
                                className={`p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all transform hover:-translate-y-2 border-t-4 cursor-pointer ${darkMode ? 'bg-gray-800 text-gray-300 border-gray-700' : 'bg-white text-gray-900 border-orange-500'}`}
                            >
                                {CardContent}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );

    // Expanded Teachers Component
    const renderTeachers = () => (
        <div className={`max-w-7xl mx-auto px-4 py-12 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Hero Section für Lehrer */}
            <div className="text-center mb-16">
                <div
                    className={`inline-flex items-center gap-2 px-4 py-2 rounded-full ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-orange-100 text-orange-600'} mb-8`}>
                    <Users className="h-4 w-4"/>
                    <span>Dein digitaler Lehreralltag</span>
                </div>
                <h2 className="text-4xl font-bold mb-4">Für Lehrkräfte</h2>
                <p className={`text-xl max-w-3xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Entdecken Sie, wie Tablets den Unterricht bereichern und neue pädagogische Möglichkeiten eröffnen.
                </p>
            </div>

            {/* Hauptvorteile */}
            <div className="grid md:grid-cols-3 gap-8 mb-16">
                {[
                    {
                        icon: Target,
                        title: "Differenzierung leicht gemacht",
                        description: "Ermöglichen Sie individuelles Lernen durch adaptive Aufgaben und personalisierte Lernpfade."
                    },
                    {
                        icon: Clock,
                        title: "Zeitersparnis & Effizienz",
                        description: "Reduzieren Sie Vorbereitungszeit durch digitale Materialien und automatisierte Korrekturen." },
                    { icon: Users, title: "Interaktive Zusammenarbeit", description: "Fördern Sie Teamarbeit durch kollaborative Tools." }
                ].map((feature, idx) => (
                    <div key={idx} className={`p-6 rounded-xl shadow-lg hover:shadow-xl transition-all ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                        <div className={`h-12 w-12 rounded-lg flex items-center justify-center mb-4 ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-orange-500 to-red-500'}`}>
                            <feature.icon className="h-6 w-6 text-white" />
                        </div>
                        <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
                        <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{feature.description}</p>
                    </div>
                ))}
            </div>

            {/* Vorteile vs. Herausforderungen */}
            <div className={`rounded-2xl shadow-lg p-8 mb-16 ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                <h3 className="text-2xl font-bold mb-8 text-center">Vorteile überwiegen Herausforderungen</h3>

                <div className="grid md:grid-cols-2 gap-12">
                    {/* Vorteile */}
                    <div>
                        <div className="flex items-center gap-2 mb-6">
                            <div className={`h-8 w-8 rounded-full flex items-center justify-center ${darkMode ? 'bg-green-600' : 'bg-green-100'}`}>
                                <ChevronRight className="h-5 w-5 text-green-500" />
                            </div>
                            <h4 className="text-xl font-bold text-green-600">Vorteile</h4>
                        </div>
                        <ul className="space-y-4">
                            {[
                                { title: "Motivationssteigerung", desc: "Höhere Schülermotivation durch interaktive und multimediale Inhalte." },
                                { title: "Effiziente Unterrichtsgestaltung", desc: "Schneller Zugriff auf Materialien und flexible Anpassung an Lernsituationen." },
                                { title: "Besseres Feedback", desc: "Unmittelbare Rückmeldung und detaillierte Lernstandsanalysen." },
                                { title: "Medienkompetenz", desc: "Förderung digitaler Fähigkeiten für die moderne Arbeitswelt." },
                                { title: "Inklusion & Differenzierung", desc: "Bessere Unterstützung verschiedener Lerntypen und -niveaus." }
                            ].map((item, idx) => (
                                <li key={idx} className="flex items-start gap-3">
                                    <div className={`h-6 w-6 rounded-full flex items-center justify-center mt-1 ${darkMode ? 'bg-green-600' : 'bg-green-100'}`}>
                                        <ChevronRight className="h-4 w-4 text-green-500" />
                                    </div>
                                    <div>
                                        <span className="font-semibold block">{item.title}</span>
                                        <span className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{item.desc}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Herausforderungen und Lösungen */}
                    <div>
                        <div className="flex items-center gap-2 mb-6">
                            <div className={`h-8 w-8 rounded-full flex items-center justify-center ${darkMode ? 'bg-orange-500' : 'bg-orange-100'}`}>
                                <Shield className="h-5 w-5 text-orange-500" />
                            </div>
                            <h4 className="text-xl font-bold underline text-orange-500">Lösbare Herausforderungen</h4>
                        </div>
                        <ul className="space-y-6">
                            {[
                                { challenge: "Technische Hürden", solution: "Umfassende Einführung und kontinuierliche Unterstützung durch unser IT-Team." },
                                { challenge: "Ablenkungspotential", solution: "Klare Regeln und spezielle Classroom-Management-Tools zur Aufmerksamkeitssteuerung." },
                                { challenge: "Zeitaufwand für Einarbeitung", solution: "Schrittweise Einführung und praxisnahe Fortbildungen nach Bedarf." }
                            ].map((item, idx) => (
                                <li key={idx} className={`p-4 rounded-lg shadow ${darkMode ? 'bg-orange-600 text-orange-200' : 'bg-orange-100 text-orange-800'}`}>
                                    <div className="flex items-start gap-3">
                                        <div className={`h-6 w-6 rounded-full flex items-center justify-center mt-1 ${darkMode ? 'bg-orange-400' : 'bg-orange-200'}`}>
                                            <Shield className="h-4 w-4 text-orange-500" />
                                        </div>
                                        <div>
                                            <span className={`font-semibold block ${darkMode ? 'text-orange-200' : 'text-orange-800'}`}>{item.challenge}</span>
                                            <span className={`${darkMode ? 'text-orange-100' : 'text-gray-600'}`}>{item.solution}</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* Zusätzliche Überzeugende Argumente */}
            <div className={`grid md:grid-cols-2 gap-12 mb-16 ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                {/* Innovatives Lernen */}
                <div className="p-6 rounded-xl shadow-lg">
                    <h4 className="text-xl font-bold mb-4">Innovatives Lernen fördern</h4>
                    <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Tablets ermöglichen den Zugang zu einer Vielzahl digitaler Werkzeuge und Ressourcen, die das Lernen spannender gestalten. Mit interaktiven Inhalten wie Lern-Apps, Videos und virtuellen Exkursionen können Schüler selbstständiger und kreativer arbeiten.
                    </p>
                </div>

                {/* Flexibilität und Anpassungsfähigkeit */}
                <div className="p-6 rounded-xl shadow-lg">
                    <h4 className="text-xl font-bold mb-4">Flexibilität und Anpassungsfähigkeit</h4>
                    <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Der Unterricht kann flexibler gestaltet werden, da Tablets den schnellen Zugang zu aktuellen Materialien ermöglichen. Lehrkräfte können Inhalte unkompliziert an die Bedürfnisse der Klasse anpassen und auf aktuelle Entwicklungen reagieren.
                    </p>
                </div>

                {/* Umfassende Leistungsanalyse */}
                <div className="p-6 rounded-xl shadow-lg">
                    <h4 className="text-xl font-bold mb-4">Umfassende Leistungsanalyse</h4>
                    <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Digitale Plattformen bieten detaillierte Einblicke in die Lernfortschritte der Schüler. Lehrkräfte können die individuellen Stärken und Schwächen ihrer Schüler besser erkennen und gezielter fördern.
                    </p>
                </div>

                {/* Förderung digitaler Kompetenzen */}
                <div className="p-6 rounded-xl shadow-lg">
                    <h4 className="text-xl font-bold mb-4">Förderung digitaler Kompetenzen</h4>
                    <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Der Einsatz von Tablets im Unterricht schult die digitalen Kompetenzen der Schüler. Sie lernen, wie sie Medien kritisch hinterfragen und digitale Tools für verschiedene Aufgaben nutzen können – Fähigkeiten, die im modernen Berufsleben unabdingbar sind.
                    </p>
                </div>
            </div>

            {/* Call to Action */}
            <div className="text-center">
                <h3 className="text-2xl font-bold mb-4">Gestalten Sie die Bildung der Zukunft mit Tablets</h3>
                <p className={`text-xl mb-6 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Nutzen Sie die Vorteile der digitalen Technologien und fördern Sie die Fähigkeiten Ihrer Schüler, um
                    sie optimal auf die Herausforderungen der modernen Welt vorzubereiten.
                </p>
                <a
                    href="/moreinfoteachers" // Setze das Ziel hier entsprechend
                    className="px-8 py-4 bg-gradient-to-r from-orange-500 to-red-500 text-white rounded-xl hover:from-orange-600 hover:to-red-600 transition-all transform hover:scale-105 shadow-lg"
                >
                    Jetzt mehr erfahren
                </a>

            </div>
        </div>
    );


    const renderStudents = () => (
        <div className="space-y-8">
            <div className="relative text-center space-y-6 py-16 px-4">
                <div
                    className={`inline-flex items-center gap-2 px-4 py-2 rounded-full ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-orange-100 text-orange-600'}`}>
                    <Users className="h-4 w-4"/>
                    <span>Dein digitaler Schulalltag</span>
                </div>
                <h1 className={`text-4xl font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Dein Tablet in der Schule
                </h1>
                <p className={`text-lg max-w-xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    So macht Lernen mehr Spaß und wird einfacher!
                </p>
            </div>

            <div className="max-w-7xl mx-auto px-4">
                {/* Einfache Regeln Box */}
                <div
                    className={`p-6 rounded-xl shadow-lg mb-6 ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                    <h3 className="text-2xl font-bold mb-4 flex items-center gap-2">
                        <Shield className="h-6 w-6 text-orange-500"/>
                        Die wichtigsten Regeln
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {[
                            {text: 'Lade dein Tablet jeden Abend auf', icon: Battery},
                            {text: 'Installiere nur erlaubte Apps', icon: Shield},
                            {text: 'Mache regelmäßig Backups', icon: Save},
                            {text: 'Halte dein Tablet sauber und sicher', icon: Sparkles}
                        ].map((rule, idx) => (
                            <div key={idx}
                                 className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-orange-50'} flex items-start gap-3`}>
                                <rule.icon className="h-5 w-5 text-orange-500 mt-1"/>
                                <span>{rule.text}</span>
                            </div>
                        ))}
                    </div>
                </div>

                {/* So bleibst du organisiert */}
                <div
                    className={`p-6 rounded-xl shadow-lg mb-6 ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                    <h3 className="text-2xl font-bold mb-4 flex items-center gap-2">
                        <BookOpen className="h-6 w-6 text-orange-500"/>
                        So bleibst du organisiert
                    </h3>
                    <div className="space-y-4">
                        <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-orange-50'}`}>
                            <h4 className="font-bold mb-2">Deine digitale Schultasche 🎒</h4>
                            <ul className="space-y-2 list-disc list-inside">
                                <li>Erstelle für jedes Fach einen eigenen Ordner</li>
                                <li>Speichere Arbeitsblätter direkt im richtigen Ordner</li>
                                <li>Nutze aussagekräftige Dateinamen (z.B. "Mathe_Hausaufgabe_2024-05-01")</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Tipps für verschiedene Lerntypen */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div
                        className={`p-6 rounded-xl shadow-lg ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                        <h4 className="font-bold mb-3 flex items-center gap-2">
                            <Lightbulb className="h-5 w-5 text-orange-500"/>
                            Für Entdecker
                        </h4>
                        <p className="text-sm">
                            Nutze Lern-Apps und interaktive Übungen, um Neues zu entdecken. Probiere verschiedene
                            Lernmethoden aus!
                        </p>
                    </div>

                    <div
                        className={`p-6 rounded-xl shadow-lg ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                        <h4 className="font-bold mb-3 flex items-center gap-2">
                            <Target className="h-5 w-5 text-orange-500"/>
                            Für Chaoten
                        </h4>
                        <p className="text-sm">
                            Das Tablet hilft dir, nichts mehr zu verlieren. Alle Unterlagen sind immer griffbereit und
                            sortiert.
                        </p>
                    </div>

                    <div
                        className={`p-6 rounded-xl shadow-lg ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                        <h4 className="font-bold mb-3 flex items-center gap-2">
                            <Star className="h-5 w-5 text-orange-500"/>
                            Für Kreative
                        </h4>
                        <p className="text-sm">
                            Erstelle tolle Präsentationen, zeichne Mindmaps oder gestalte digitale Plakate!
                        </p>
                    </div>
                </div>

                {/* Hilfreiche Erinnerungen */}
                <div
                    className={`mt-6 p-6 rounded-xl shadow-lg ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                    <h3 className="text-xl font-bold mb-4 flex items-center gap-2">
                        <Clock className="h-6 w-6 text-orange-500"/>
                        Nicht vergessen!
                    </h3>
                    <div className="space-y-3">
                        <p>👉 In den Pausen ist das Tablet in der Tasche</p>
                        <p>👉 Spiele nur in den erlaubten Zeiten</p>
                        <p>👉 Frag bei Problemen deine Lehrer um Hilfe</p>
                        <p>👉 Mach regelmäßig Screenshots von wichtigen Notizen</p>
                    </div>
                </div>

                {/* Motivierende Abschlussbox */}
                <div className="text-center mt-8">
                    <div
                        className={`p-6 rounded-xl shadow-lg inline-block ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                        <h3 className="text-xl font-bold mb-2">Du schaffst das! 💪</h3>
                        <p className="text-sm">
                            Mit deinem Tablet kannst du besser und einfacher lernen.
                            Probier die verschiedenen Möglichkeiten aus und finde deinen eigenen Weg!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderParents = () => (
        <div className="space-y-8">
            <div className="relative text-center space-y-6 py-16 px-4">
                <div
                    className={`inline-flex items-center gap-2 px-4 py-2 rounded-full ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-orange-100 text-orange-600'}`}>
                    <Shield className="h-4 w-4"/>
                    <span>Zukunftskompetenz durch digitales Lernen</span>
                </div>
                <h1 className={`text-4xl font-bold ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Digitale Bildung für die Zukunft Ihres Kindes
                </h1>
                <p className={`text-lg max-w-xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Frühzeitige Medienkompetenz als Schlüssel zum Erfolg
                </p>
            </div>

            <div className="max-w-7xl mx-auto px-4">
                {/* Digitale Zukunft */}
                <div
                    className={`p-6 rounded-xl shadow-lg mb-6 ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                    <h3 className="text-2xl font-bold mb-4 flex items-center gap-2">
                        <Rocket className="h-6 w-6 text-orange-500"/>
                        Die digitale Zukunft aktiv gestalten
                    </h3>
                    <div className="space-y-4">
                        <p className="text-lg">
                            In einer Welt, die zunehmend von Künstlicher Intelligenz und digitalen Technologien geprägt
                            ist,
                            wird der kompetente Umgang mit diesen Werkzeugen immer wichtiger. Je früher Kinder lernen,
                            diese Technologien sinnvoll zu nutzen, desto besser sind sie auf die Zukunft vorbereitet.
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {[
                                {
                                    title: "KI im Alltag",
                                    desc: "Frühzeitiges Verständnis für KI-Tools und deren sinnvolle Nutzung entwickeln"
                                },
                                {
                                    title: "Digitale Mündigkeit",
                                    desc: "Eigenverantwortlicher und kritischer Umgang mit digitalen Medien"
                                },
                                {
                                    title: "Fake News erkennen",
                                    desc: "Quellen hinterfragen und Informationen richtig einordnen lernen"
                                },
                                {
                                    title: "Digitale Zusammenarbeit",
                                    desc: "Teamarbeit und Kommunikation in digitalen Umgebungen"
                                }
                            ].map((item, idx) => (
                                <div key={idx}
                                     className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-orange-50'}`}>
                                    <h4 className="font-bold mb-2">{item.title}</h4>
                                    <p className="text-sm">{item.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Konkrete Vorteile */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                    {[
                        {
                            title: "Vorbereitung auf die Arbeitswelt",
                            desc: "Fast alle Berufe erfordern heute digitale Kompetenzen. Frühe Tablet-Nutzung bereitet optimal auf diese Anforderungen vor.",
                            icon: Target
                        },
                        {
                            title: "Kritisches Denken fördern",
                            desc: "Durch den begleiteten Umgang mit digitalen Medien lernen Kinder früh, Informationen zu bewerten und zu hinterfragen.",
                            icon: Lightbulb
                        },
                        {
                            title: "Kreativität entfalten",
                            desc: "Digitale Tools eröffnen neue Möglichkeiten, sich kreativ auszudrücken und Ideen umzusetzen.",
                            icon: Sparkles
                        }
                    ].map((item, idx) => (
                        <div key={idx}
                             className={`p-6 rounded-xl shadow-lg ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                            <h4 className="font-bold mb-3 flex items-center gap-2">
                                <item.icon className="h-5 w-5 text-orange-500"/>
                                {item.title}
                            </h4>
                            <p className="text-sm">{item.desc}</p>
                        </div>
                    ))}
                </div>

                {/* Zukunftskompetenzen */}
                <div
                    className={`p-6 rounded-xl shadow-lg mb-6 ${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-900'}`}>
                    <h3 className="text-xl font-bold mb-4 flex items-center gap-2">
                        <Star className="h-6 w-6 text-orange-500"/>
                        Wichtige Zukunftskompetenzen
                    </h3>
                    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-orange-50'}`}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <h4 className="font-bold mb-3">Technische Fähigkeiten:</h4>
                                <ul className="space-y-2">
                                    <li className="flex items-start gap-2">
                                        <ChevronRight className="h-5 w-5 text-orange-500 flex-shrink-0"/>
                                        <span>Sicherer Umgang mit digitalen Werkzeugen</span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <ChevronRight className="h-5 w-5 text-orange-500 flex-shrink-0"/>
                                        <span>Verständnis für digitale Prozesse</span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <ChevronRight className="h-5 w-5 text-orange-500 flex-shrink-0"/>
                                        <span>Grundlegendes KI-Verständnis</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h4 className="font-bold mb-3">Soziale Kompetenzen:</h4>
                                <ul className="space-y-2">
                                    <li className="flex items-start gap-2">
                                        <ChevronRight className="h-5 w-5 text-orange-500 flex-shrink-0"/>
                                        <span>Digitale Kommunikation</span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <ChevronRight className="h-5 w-5 text-orange-500 flex-shrink-0"/>
                                        <span>Online-Zusammenarbeit</span>
                                    </li>
                                    <li className="flex items-start gap-2">
                                        <ChevronRight className="h-5 w-5 text-orange-500 flex-shrink-0"/>
                                        <span>Verantwortungsvoller Medienumgang</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    const renderFAQ = () => (
        <FAQSection darkMode={darkMode} />
    );

    return (
        <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Header */}
            <header className="bg-white dark:bg-gray-800 border-b border-gray-200 fixed w-full top-0 z-50">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex items-center justify-between h-20">
                        <div className="flex items-center gap-3">
                            <div
                                className="h-10 w-10 bg-gradient-to-br from-orange-500 to-red-500 rounded-xl flex items-center justify-center">
                                <Tablet className="h-6 w-6 text-white"/>
                            </div>
                            <span
                                className={`text-2xl font-bold bg-clip-text text-transparent ${darkMode ? 'bg-gradient-to-r from-blue-400 to-white' : 'bg-gradient-to-r from-orange-500 to-red-500'}`}>
                                TabletSchule
                            </span>
                        </div>

                        <nav className="hidden md:block">
                            <ul className="flex space-x-8">
                                {navigationItems.map((item) => (
                                    <li key={item.id}>
                                        <button
                                            onClick={() => setActiveTab(item.id)}
                                            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all
                                                ${activeTab === item.id ? 'text-white bg-gradient-to-r from-orange-500 to-red-500 shadow-lg' : 'text-gray-600 hover:text-orange-500'}`}
                                                >
                                            <item.icon className="h-5 w-5"/>
                                            <span>{item.title}</span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        {/* Dark Mode Toggle */}
                        <button onClick={toggleDarkMode} className="p-2 md:ml-4">
                            {darkMode ? <Sun className="h-6 w-6 text-yellow-500"/> :
                                <Moon className="h-6 w-6 text-gray-700"/>}
                        </button>

                        {/* Mobile Menu Button */}
                        <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="md:hidden p-2">
                            {mobileMenuOpen ? <X className="h-6 w-6"/> : <Menu className="h-6 w-6"/>}
                        </button>
                    </div>
                </div>
                {mobileMenuOpen && (
                    <nav className="md:hidden border-t border-gray-200">
                        <ul className="px-2 pt-2 pb-3 space-y-1">
                            {navigationItems.map((item) => (
                                <li key={item.id}>
                                    <button onClick={() => {
                                        setActiveTab(item.id);
                                        setMobileMenuOpen(false);

                                    }}
                                        className={`w-full flex items-center gap-2 px-4 py-3 rounded-lg transition-all
                                            ${activeTab === item.id ? 'text-white bg-gradient-to-r from-orange-500 to-red-500' : 'text-gray-600 hover:text-orange-500'}`}
                                        >
                                        <item.icon className="h-5 w-5"/>
                                        <span>{item.title}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
            </header>
            <main className="pt-20 transition-all duration-300 ease-in-out">
                {activeTab === 'home' && renderHome()}
                {activeTab === 'teachers' && renderTeachers()}
                {activeTab === 'students' && renderStudents()}
                {activeTab === 'parents' && renderParents()}
                {activeTab === 'faq' && renderFAQ()}
                {activeTab === 'downloads' && <DownloadPage darkMode={darkMode}/>}
            </main>
            <footer
                className={`bg-gradient-to-r ${darkMode ? 'from-gray-800 to-gray-900' : 'from-orange-100 to-red-100'} text-gray-700 dark:text-gray-300 py-8 mt-12`}>
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                        <div className="text-center md:text-left">
                            <p className={`text-sm font-semibold ${darkMode ? 'text-gray-300' : 'text-gray-800'}`}>
                                © 2024 TabletSchule. Alle Rechte vorbehalten.
                            </p>
                        </div>
                        <div className="flex space-x-4 text-sm">
                            <a href="/daten"
                                className={`${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-800 hover:text-orange-600'} transition-colors duration-300`}>
                                Datenschutz
                            </a>
                            <a href="/impressum"
                                className={`${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-800 hover:text-orange-600'} transition-colors duration-300`}>
                                Impressum
                            </a>
                            <a href="/agb"
                                className={`${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-800 hover:text-orange-600'} transition-colors duration-300`}>
                                AGB
                            </a>
                            <a href="/contact"
                                className={`${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-800 hover:text-orange-600'} transition-colors duration-300`}>
                                Kontakt
                            </a>
                            <a href="/aboutme2"
                                className={`${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-800 hover:text-orange-600'} transition-colors duration-300`}>
                                Über mich
                            </a>
                        </div>
                    </div>
                    <div className="mt-4 text-xs text-center md:text-left text-gray-600 dark:text-gray-400">
                        <p>Hinweis: Diese Website dient nur zu Demonstrationszwecken und erhebt keine Daten von Nutzern.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default TabletWebsite;

