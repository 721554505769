import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importiere useNavigate
import { Clock, Target, ArrowLeft, CheckCircle, Star, Heart, Award, Compass, User } from 'lucide-react';

const IndividualLearningPage = () => {
    const [darkMode, setDarkMode] = useState(false);
    const navigate = useNavigate(); // Initialisiere useNavigate

    const benefits = [
        {
            icon: Clock,
            title: "Flexibles Zeitmanagement",
            description: "Lerne dann, wenn du am aufnahmefähigsten bist und plane deine Lernzeiten nach deinem persönlichen Rhythmus."
        },
        {
            icon: Target,
            title: "Individuelle Ziele",
            description: "Setze dir eigene Lernziele und erreiche sie Schritt für Schritt in deinem Tempo."
        },
        {
            icon: Star,
            title: "Stärkenorientiert",
            description: "Konzentriere dich auf deine Talente und baue sie gezielt aus."
        },
        {
            icon: Compass,
            title: "Persönlicher Lernweg",
            description: "Wähle aus verschiedenen Lernmaterialien die für dich am besten geeigneten aus."
        },
        {
            icon: Heart,
            title: "Stressfreies Lernen",
            description: "Ohne Zeitdruck kannst du dich besser auf die Inhalte konzentrieren und nachhaltig lernen."
        },
        {
            icon: Award,
            title: "Erfolgserlebnisse",
            description: "Erreiche deine Ziele in deinem Tempo und feiere jeden Fortschritt."
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Navigation Button */}
            <button
                onClick={() => navigate(-1)} // Verwende navigate, um zur vorherigen Seite zu gelangen
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-green-500 to-emerald-500 text-white rounded-lg hover:from-green-600 hover:to-emerald-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-green-500 to-emerald-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-green-100 text-green-600'}`}>
                        <User className="h-5 w-5"/>
                        <span className="font-medium">Dein Weg zum Lernerfolg</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-green-500 to-emerald-500'} text-transparent bg-clip-text`}>
                            Lerne in deinem eigenen Tempo zum Erfolg
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Entdecke die Freiheit des individuellen Lernens. Bei uns bestimmst du das Tempo und den Weg zu deinem persönlichen Lernerfolg.
                    </p>
                </div>
            </div>

            {/* Benefits Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Vorteile des individuellen Lerntempos
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {benefits.map((benefit, idx) => (
                        <div key={idx} className={`p-6 rounded-xl hover:shadow-xl transition-all ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-green-50'}`}>
                            <div className={`h-12 w-12 mx-auto rounded-lg flex items-center justify-center mb-4 ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-green-500 to-emerald-500'}`}>
                                <benefit.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Starte jetzt deinen persönlichen Lernweg
                </h2>
                <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Entdecke, wie du durch individuell angepasstes Lernen nicht nur bessere Ergebnisse erzielst, sondern auch mehr Freude am Lernen entwickelst.
                </p>
                <button className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-green-500 to-emerald-500 text-white rounded-xl hover:from-green-600 hover:to-emerald-600 transition-all transform hover:scale-105 shadow-lg">
                    Kostenlos starten <Target className="h-5 w-5 ml-2"/>
                </button>
            </div>

            {/* Dark Mode Toggle */}
            <button
                onClick={() => setDarkMode(!darkMode)}
                className="fixed bottom-4 right-4 p-3 rounded-full bg-gradient-to-r from-green-500 to-emerald-500 text-white shadow-lg hover:shadow-xl transition-all"
            >
                {darkMode ? '☀️' : '🌙'}
            </button>
        </div>
    );
};

export default IndividualLearningPage;

