import { BrowserRouter as Router, Route, Routes,} from 'react-router-dom';
import TabletWebsite from "./components/home-test";
import PrivacyPolicy from "./components/daten";
import Impressum from "./components/impressum";
import TermsOfService from "./components/agb";
import Contact from "./components/contact";
import ModernMoreInfo from "./components/moreinfoteachers";
import DigitalTransformationPage from "./components/transform";
import ScrollToTop from "./components/ScrollToTop";
import InnovativeLearningPage from "./components/learning";
import IndividualLearningPage from "./components/individual";
import CreativeProjectsPage from "./components/creative";
import AIEducationPage from "./components/ki";
import AboutMePage from "./components/aboutme2";
import LanguageLearningPage from "./components/languages";
import MediaLiteracyPage from "./components/media-literacy";
import IndividualLearningPages from "./components/individual-learning";
import DownloadPage from "./components/downlaodcenter";
const App = () => {

    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<TabletWebsite />} />
                <Route path="/daten" element={<PrivacyPolicy />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/agb" element={<TermsOfService />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/moreinfoteachers" element={<ModernMoreInfo />} />
                <Route path="/transform" element={<DigitalTransformationPage />} />
                <Route path="/learning" element={<InnovativeLearningPage />} />
                <Route path="/individual" element={<IndividualLearningPage />} />
                <Route path="/creative" element={<CreativeProjectsPage />} />
                <Route path="/aboutme" element={<AboutMePage />} />
                <Route path="/aboutme2" element={<AboutMePage />} />
                <Route path="/ki" element={<AIEducationPage />} />
                <Route path="/languages" element={<LanguageLearningPage />} />
                <Route path="/media-literacy" element={<MediaLiteracyPage />} />
                <Route path="/individual-learning" element={<IndividualLearningPages />} />
                <Route path="/download" element={<DownloadPage />} />
            </Routes>
        </Router>
    );
}

export default App;