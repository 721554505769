import React, { useState } from 'react';
import {
    Smartphone,
    ArrowLeft,
    CheckCircle,
    Rocket,
    Shield,
    Search,
    Users,
    FileSearch,
    Share2,
    Camera
} from 'lucide-react';

const MediaLiteracyPage = () => {
    const [darkMode, setDarkMode] = useState(false);

    const benefits = [
        {
            icon: Shield,
            title: "Sicheres Surfen",
            description: "Lernen, wie man sich sicher im Internet bewegt und persönliche Daten schützt."
        },
        {
            icon: Search,
            title: "Kritisches Denken",
            description: "Entwicklung von Fähigkeiten zur Bewertung von Online-Informationen und Quellen."
        },
        {
            icon: Users,
            title: "Digitale Etikette",
            description: "Respektvoller Umgang in sozialen Medien und Online-Kommunikation."
        },
        {
            icon: FileSearch,
            title: "Fake News erkennen",
            description: "Methoden zur Unterscheidung zwischen verlässlichen und irreführenden Informationen."
        },
        {
            icon: Share2,
            title: "Verantwortungsvolles Teilen",
            description: "Bewusstsein für die Auswirkungen des eigenen Online-Verhaltens entwickeln."
        },
        {
            icon: Camera,
            title: "Kreative Mediengestaltung",
            description: "Aktive und kreative Gestaltung digitaler Inhalte mit verschiedenen Tools."
        }
    ];

    const competencies = [
        {
            title: "Technische Fähigkeiten",
            points: [
                "Effektive Nutzung digitaler Geräte",
                "Umgang mit verschiedenen Apps und Programmen",
                "Grundlegendes Verständnis von Datensicherheit"
            ]
        },
        {
            title: "Soziale Kompetenzen",
            points: [
                "Respektvolle Online-Kommunikation",
                "Cybermobbing erkennen und vermeiden",
                "Digitale Zusammenarbeit"
            ]
        },
        {
            title: "Kritisches Denken",
            points: [
                "Informationsquellen bewerten",
                "Fake News identifizieren",
                "Manipulationsversuche erkennen"
            ]
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Zurück-Button */}
            <button
                onClick={() => window.history.back()}
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-rose-600 to-red-600 text-white rounded-lg hover:from-rose-700 hover:to-red-700 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-rose-600 to-red-600'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-rose-100 text-rose-600'}`}>
                        <Smartphone className="h-5 w-5"/>
                        <span className="font-medium">Fit für die digitale Welt</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-rose-600 to-red-600'} text-transparent bg-clip-text`}>
                            Medienkompetenz für die Generation Digital
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Entdecken Sie, wie wir Schüler*innen dabei unterstützen,
                        verantwortungsvoll und sicher mit digitalen Medien umzugehen.
                    </p>
                </div>
            </div>

            {/* Vorteile Grid */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Kernkompetenzen der digitalen Bildung
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {benefits.map((benefit, idx) => (
                        <div key={idx}
                             className={`p-6 rounded-xl hover:shadow-xl transition-all transform hover:scale-105 
                             ${darkMode ? 'bg-gray-800 hover:bg-gray-750' : 'bg-white hover:bg-rose-50'}`}>
                            <div className={`h-12 w-12 mx-auto rounded-lg flex items-center justify-center mb-4 
                                ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-rose-600 to-red-600'}`}>
                                <benefit.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Kompetenzbereiche Section */}
            <div className="max-w-7xl mx-auto px-4 py-16">
                <div className={`rounded-2xl p-8 ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl`}>
                    <h2 className={`text-3xl md:text-4xl font-bold mb-12 text-center ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        Aufbau digitaler Kompetenzen
                    </h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        {competencies.map((comp, idx) => (
                            <div key={idx} className="space-y-4">
                                <h3 className={`text-xl font-bold mb-4 text-center ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                    {comp.title}
                                </h3>
                                {comp.points.map((point, pointIdx) => (
                                    <div key={pointIdx} className={`flex items-start gap-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                        <CheckCircle className="h-5 w-5 text-rose-600 flex-shrink-0 mt-1" />
                                        <p>{point}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <div className={`rounded-2xl p-8 ${darkMode ? 'bg-gray-800' : 'bg-gradient-to-r from-rose-600 to-red-600'}`}>
                    <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-white'}`}>
                        Starten Sie jetzt mit digitaler Bildung
                    </h2>
                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-white opacity-90'}`}>
                        Bereiten Sie Ihre Schüler*innen auf die Herausforderungen der digitalen Welt vor
                        und fördern Sie einen verantwortungsvollen Umgang mit Medien.
                    </p>
                    <button className={`inline-flex items-center px-8 py-4 bg-white text-rose-600 rounded-xl 
                        hover:bg-rose-50 transition-all transform hover:scale-105 shadow-lg
                        ${darkMode ? 'bg-rose-600 text-white hover:bg-rose-700' : ''}`}>
                        Mehr erfahren <Rocket className="h-5 w-5 ml-2"/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MediaLiteracyPage;