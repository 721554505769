import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen bg-gray-50 text-gray-900 px-4 py-12">
            <div className="max-w-3xl mx-auto space-y-8">
                <h1 className="text-4xl font-bold text-center">Datenschutzerklärung</h1>

                <section>
                    <h2 className="text-2xl font-semibold">1. Verantwortliche Stelle</h2>
                    <p>
                        Verantwortlich für die Datenverarbeitung auf dieser Website ist:
                    </p>
                    <p className="mt-2">
                        <strong>[Ihr Name / Ihre Firma]</strong> <br />
                        [Ihre Adresse] <br />
                        [Ihre Telefonnummer] <br />
                        [Ihre E-Mail-Adresse]
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h2>

                    <h3 className="text-xl font-medium mt-4">a) Beim Besuch der Website</h3>
                    <p>
                        Beim Aufrufen unserer Website <strong>[Website-URL]</strong> werden durch den Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in sogenannten Logfiles gespeichert. Folgende Informationen werden ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
                    </p>
                    <ul className="list-disc ml-6 mt-2">
                        <li>IP-Adresse des anfragenden Rechners</li>
                        <li>Datum und Uhrzeit des Zugriffs</li>
                        <li>Name und URL der abgerufenen Datei</li>
                        <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                        <li>Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
                    </ul>
                    <p className="mt-2">
                        Diese Daten werden verarbeitet, um die Nutzung der Website zu ermöglichen (Verbindungsaufbau), die Systemsicherheit und -stabilität zu gewährleisten sowie zu administrativen Zwecken. Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">3. Weitergabe von Daten</h2>
                    <p>
                        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den folgenden Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
                    </p>
                    <ul className="list-disc ml-6 mt-2">
                        <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben (Art. 6 Abs. 1 S. 1 lit. a DSGVO),</li>
                        <li>die Weitergabe zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben (Art. 6 Abs. 1 S. 1 lit. f DSGVO),</li>
                        <li>eine gesetzliche Verpflichtung zur Weitergabe besteht (Art. 6 Abs. 1 S. 1 lit. c DSGVO), sowie</li>
                        <li>die Weitergabe für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist (Art. 6 Abs. 1 S. 1 lit. b DSGVO).</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">4. Cookies und Tracking-Tools</h2>
                    <p>
                        Auf dieser Website werden derzeit keine Cookies, Tracking-Tools oder sonstige Analyseprogramme verwendet, die personenbezogene Daten erheben.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">5. Betroffenenrechte</h2>
                    <p>
                        Sie haben das Recht:
                    </p>
                    <ul className="list-disc ml-6 mt-2">
                        <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen,</li>
                        <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,</li>
                        <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,</li>
                        <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,</li>
                        <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen,</li>
                        <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit uns gegenüber zu widerrufen und</li>
                        <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">6. Datensicherheit</h2>
                    <p>
                        Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. Darüber hinaus nutzen wir geeignete technische und organisatorische Sicherheitsmaßnahmen, um Ihre Daten gegen Manipulationen, teilweisen oder vollständigen Verlust sowie gegen unbefugten Zugriff Dritter zu schützen.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold">7. Aktualität und Änderung dieser Datenschutzerklärung</h2>
                    <p>
                        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand **[Datum einfügen]**. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
