import React, { useState } from 'react';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Hier können Sie den Datenversand einbauen (z.B. an eine API senden)
        setSubmitted(true);
    };

    return (
        <div className="min-h-screen bg-gray-50 text-gray-900 px-4 py-12">
            <div className="max-w-2xl mx-auto space-y-8">
                <h1 className="text-4xl font-bold text-center">Kontakt</h1>
                <p className="text-center text-gray-600">
                    Haben Sie Fragen? Füllen Sie das Formular aus, und wir melden uns so schnell wie möglich bei Ihnen.
                </p>

                {!submitted ? (
                    <form onSubmit={handleSubmit} className="space-y-6 bg-white p-8 rounded-xl shadow-lg">
                        <div>
                            <label htmlFor="name" className="block text-lg font-medium">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-lg font-medium">
                                E-Mail-Adresse
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="message" className="block text-lg font-medium">
                                Nachricht
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                rows="5"
                                className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                            />
                        </div>

                        <button
                            type="submit"
                            className="w-full py-3 bg-gradient-to-r from-orange-500 to-red-500 text-white font-semibold rounded-lg hover:from-orange-600 hover:to-red-600 transition-all"
                        >
                            Nachricht senden
                        </button>
                    </form>
                ) : (
                    <div className="text-center p-8 bg-green-50 rounded-xl shadow-lg">
                        <h2 className="text-2xl font-semibold text-green-600">Vielen Dank für Ihre Nachricht!</h2>
                        <p className="mt-4 text-gray-600">Wir werden uns in Kürze bei Ihnen melden.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Contact;
