import React, { useState, useEffect } from 'react';
import {
    Download,
    FileText,
    Search,
    Info,
    BookOpen,
    Settings,
    Shield,
    Users,
    HelpCircle
} from 'lucide-react';

const DownloadPage = () => {
    const [darkMode, setDarkMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [documents, setDocuments] = useState([]);

    const categories = [
        { id: 'all', name: 'Alle Dokumente', icon: FileText },
        { id: 'rules', name: 'Tablet-Regeln', icon: Shield },
        { id: 'info', name: 'Informationen', icon: Info },
        { id: 'tips', name: 'Tipps & Tricks', icon: HelpCircle },
        { id: 'setup', name: 'Einrichtung', icon: Settings },
        { id: 'education', name: 'Unterricht', icon: BookOpen }
    ];

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const getFileSize = async (filePath) => {
        try {
            const response = await fetch(filePath, { method: 'HEAD' });
            const size = response.headers.get('content-length');
            return formatFileSize(parseInt(size));
        } catch (error) {
            console.error('Fehler beim Abrufen der Dateigröße:', error);
            return 'Größe unbekannt';
        }
    };

    const handleDownload = async (doc) => {
        try {
            const response = await fetch(doc.filePath);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = doc.title + '.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Fehler beim Download:', error);
            alert('Fehler beim Download der Datei. Bitte versuchen Sie es später erneut.');
        }
    };

    useEffect(() => {
        const loadDocuments = async () => {
            const docsData = [
                {
                    title: "Tablet-Nutzungsvereinbarung",
                    description: "Grundlegende Regeln für die Tablet-Nutzung im Unterricht",
                    category: "rules",
                    type: "PDF",
                    filePath: "/pdfs/tablet-rules.pdf",
                    date: "01.03.2024",
                    icon: Shield
                },
                {
                    title: "Erste Schritte mit dem Schul-Tablet",
                    description: "Leitfaden für die ersten Schritte mit dem neuen Schul-Tablet",
                    category: "setup",
                    type: "PDF",
                    filePath: "/pdfs/tablet-guide.pdf",
                    date: "15.02.2024",
                    icon: Settings
                },
                {
                    title: "Digitales Lernen - Best Practices",
                    description: "Tipps für effektives Lernen mit dem Tablet",
                    category: "tips",
                    type: "PDF",
                    filePath: "/pdfs/digital-learning.pdf",
                    date: "10.03.2024",
                    icon: HelpCircle
                },
                {
                    title: "Sicherheitsrichtlinien",
                    description: "Wichtige Informationen zum Datenschutz und zur Sicherheit",
                    category: "info",
                    type: "PDF",
                    filePath: "/pdfs/security.pdf",
                    date: "05.03.2024",
                    icon: Shield
                },
                {
                    title: "Apps im Unterricht",
                    description: "Übersicht empfohlener Lern-Apps und deren Einsatz",
                    category: "education",
                    type: "PDF",
                    filePath: "/pdfs/apps.pdf",
                    date: "20.02.2024",
                    icon: BookOpen
                },
                {
                    title: "Tablet-Pflege und Wartung",
                    description: "Hinweise zur Pflege und Wartung des Tablets",
                    category: "info",
                    type: "PDF",
                    filePath: "/pdfs/maintenance.pdf",
                    date: "25.02.2024",
                    icon: Settings
                },
                {
                    title: "Digitale Zusammenarbeit",
                    description: "Leitfaden für die erfolgreiche Zusammenarbeit mit Tablets",
                    category: "education",
                    type: "PDF",
                    filePath: "/pdfs/collaboration.pdf",
                    date: "12.03.2024",
                    icon: Users
                },
                {
                    title: "Problemlösungen",
                    description: "Häufige Probleme und deren Lösungen",
                    category: "tips",
                    type: "PDF",
                    filePath: "/pdfs/troubleshooting.pdf",
                    date: "08.03.2024",
                    icon: HelpCircle
                },
                {
                    title: "Tablet in Schule ",
                    description: "Informationen zur Integration des Tablets in den Schulalltag",
                    category: "info",
                    type: "PDF",
                    filePath: "/pdfs/Tablets-in-Schulen.pdf",
                    date: "03.03.2024",
                    icon: Info
                }
            ];

            const docsWithSize = await Promise.all(
                docsData.map(async (doc) => {
                    try {
                        const size = await getFileSize(doc.filePath);
                        return { ...doc, size };
                    } catch (error) {
                        return { ...doc, size: 'Größe unbekannt' };
                    }
                })
            );

            setDocuments(docsWithSize);
        };

        loadDocuments();
    }, []);

    const filteredDocuments = documents.filter(doc => {
        const matchesSearch = doc.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            doc.description.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory === 'all' || doc.category === selectedCategory;
        return matchesSearch && matchesCategory;
    });

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            <div className="relative max-w-7xl mx-auto px-4 py-16 text-center">
                <h1 className={`text-3xl md:text-4xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                    Download-Center
                </h1>
                <p className={`text-xl mb-8 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    Hier finden Sie alle wichtigen Dokumente rund um die Tablet-Nutzung
                </p>

                <div className="max-w-2xl mx-auto relative">
                    <input
                        type="text"
                        placeholder="Dokumente durchsuchen..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={`w-full px-4 py-3 rounded-lg pl-12 
                            ${darkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-gray-900 border-gray-200'} 
                            border focus:ring-2 focus:ring-cyan-500 focus:border-transparent`}
                    />
                    <Search className={`absolute left-4 top-3.5 h-5 w-5 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 pb-16">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredDocuments.map((doc, idx) => (
                        <div
                            key={idx}
                            className={`p-6 rounded-xl transition-all hover:scale-105
                                ${darkMode ? 'bg-gray-800 hover:bg-gray-750' : 'bg-white hover:shadow-lg'}`}
                        >
                            <div className="flex items-start gap-4">
                                <div className={`p-3 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-cyan-100'}`}>
                                    <doc.icon className={`h-6 w-6 ${darkMode ? 'text-cyan-400' : 'text-cyan-600'}`} />
                                </div>
                                <div className="flex-1">
                                    <h3 className="text-lg font-semibold mb-2">{doc.title}</h3>
                                    <p className={`text-sm mb-4 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                        {doc.description}
                                    </p>
                                    <button
                                        className="flex items-center gap-2 px-3 py-1.5 text-sm rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:from-cyan-600 hover:to-blue-600 transition-all"
                                        onClick={() => handleDownload(doc)}
                                    >
                                        <Download className="h-4 w-4" />
                                        Download
                                    </button>
                                    <div className={`mt-2 text-sm ${darkMode ? 'text-gray-500' : 'text-gray-500'}`}>
                                        <span>{doc.size} • {doc.type}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;
