import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importiere useNavigate
import { BookOpen, Brain, Users, ArrowLeft, CheckCircle, Lightbulb } from 'lucide-react';

const InnovativeLearningPage = () => {
    const [darkMode, setDarkMode] = useState(false);
    const navigate = useNavigate(); // useNavigate Hook initialisieren

    const learningMethods = [
        {
            icon: Brain,
            title: "Personalisiertes Lernen",
            description: "Lernpfade und Inhalte, die sich an individuelle Bedürfnisse und Lerngeschwindigkeiten anpassen."
        },
        {
            icon: Users,
            title: "Kollaboratives Lernen",
            description: "Gemeinsames Erarbeiten von Wissen durch Gruppenarbeit und Peer-Learning-Aktivitäten."
        },
        {
            icon: BookOpen,
            title: "Blended Learning",
            description: "Kombination aus digitalem Selbststudium und persönlicher Betreuung für optimale Lernergebnisse."
        },
        {
            icon: Lightbulb,
            title: "Projektbasiertes Lernen",
            description: "Praxisnahe Projekte fördern das Verständnis und die Anwendung des Gelernten."
        },
        {
            icon: CheckCircle,
            title: "Gamifiziertes Lernen",
            description: "Spielerische Elemente steigern die Motivation und machen das Lernen interessanter."
        },
        {
            icon: Brain,
            title: "Adaptives Lernen",
            description: "KI-gestützte Systeme passen sich kontinuierlich an den Lernfortschritt an."
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Navigation Button */}
            <button
                onClick={() => navigate(-1)} // Zurück zur vorherigen Seite navigieren
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-blue-500 to-purple-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-blue-100 text-blue-600'}`}>
                        <Lightbulb className="h-5 w-5"/>
                        <span className="font-medium">Entdecke die Zukunft des Lernens</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-blue-500 to-purple-500'} text-transparent bg-clip-text`}>
                            Innovative Lernmethoden für nachhaltige Erfolge
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Entdecken Sie moderne Lernansätze, die Motivation und Lernerfolg steigern. Unsere innovativen Methoden vereinen Technologie und Pädagogik für ein optimales Lernerlebnis.
                    </p>
                </div>
            </div>

            {/* Learning Methods Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Unsere innovativen Lernmethoden
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {learningMethods.map((method, idx) => (
                        <div key={idx} className={`p-6 rounded-xl hover:shadow-xl transition-all ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-blue-50'}`}>
                            <div className={`h-12 w-12 mx-auto rounded-lg flex items-center justify-center mb-4 ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-blue-500 to-purple-500'}`}>
                                <method.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{method.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{method.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Bereit für neue Lernerfahrungen?
                </h2>
                <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Starten Sie jetzt Ihre Lernreise mit unseren innovativen Methoden und erleben Sie, wie effektiv und motivierend modernes Lernen sein kann.
                </p>
                <button className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-xl hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 shadow-lg">
                    Jetzt starten <BookOpen className="h-5 w-5 ml-2"/>
                </button>
            </div>

            {/* Dark Mode Toggle */}
            <button
                onClick={() => setDarkMode(!darkMode)}
                className="fixed bottom-4 right-4 p-3 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow-lg hover:shadow-xl transition-all"
            >
                {darkMode ? '☀️' : '🌙'}
            </button>
        </div>
    );
};

export default InnovativeLearningPage;
