import React, { useState } from 'react';
import {
    PersonStanding,
    ArrowLeft,
    CheckCircle,
    Rocket,
    Target,
    Clock,
    LineChart,
    BookOpen,
    Medal,
    Puzzle
} from 'lucide-react';

const IndividualLearningPages = () => {
    const [darkMode, setDarkMode] = useState(false);

    const benefits = [
        {
            icon: Target,
            title: "Personalisierte Lernziele",
            description: "Individuelle Anpassung der Lernziele und Aufgaben an das Niveau jedes einzelnen Schülers."
        },
        {
            icon: Clock,
            title: "Eigenes Lerntempo",
            description: "Schüler*innen können Inhalte in ihrer eigenen Geschwindigkeit bearbeiten und wiederholen."
        },
        {
            icon: LineChart,
            title: "Lernfortschritt tracken",
            description: "Detaillierte Übersicht über persönliche Entwicklung und Erfolge im Lernprozess."
        },
        {
            icon: BookOpen,
            title: "Flexible Lernwege",
            description: "Verschiedene Zugänge zu Lerninhalten durch multimediale Aufbereitung."
        },
        {
            icon: Medal,
            title: "Direkte Erfolgserlebnisse",
            description: "Sofortiges Feedback und Motivation durch kleine Lernerfolge."
        },
        {
            icon: Puzzle,
            title: "Adaptive Übungen",
            description: "Automatische Anpassung der Aufgabenschwierigkeit an den Lernfortschritt."
        }
    ];

    const learningTypes = [
        {
            title: "Visuelle Lerner",
            content: [
                "Interaktive Grafiken",
                "Video-Tutorials",
                "Mind-Maps",
                "Animierte Erklärungen"
            ]
        },
        {
            title: "Auditive Lerner",
            content: [
                "Hörbücher",
                "Podcasts",
                "Gesprochene Erklärungen",
                "Audio-Feedback"
            ]
        },
        {
            title: "Kinästhetische Lerner",
            content: [
                "Interaktive Übungen",
                "Praktische Anwendungen",
                "Bewegliche Elemente",
                "Hands-on Projekte"
            ]
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Zurück-Button */}
            <button
                onClick={() => window.history.back()}
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-pink-500 to-rose-500 text-white rounded-lg hover:from-pink-600 hover:to-rose-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-pink-500 to-rose-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-pink-100 text-pink-600'}`}>
                        <PersonStanding className="h-5 w-5"/>
                        <span className="font-medium">Lernen im eigenen Tempo</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-pink-500 to-rose-500'} text-transparent bg-clip-text`}>
                            Individualisiertes Lernen für maximalen Erfolg
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Entdecken Sie, wie digitale Tools jeden Schüler individuell fördern und
                        unterschiedliche Lerntypen optimal unterstützen.
                    </p>
                </div>
            </div>

            {/* Benefits Grid */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Vorteile des individualisierten Lernens
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {benefits.map((benefit, idx) => (
                        <div key={idx}
                             className={`p-6 rounded-xl hover:shadow-xl transition-all transform hover:scale-105 
                             ${darkMode ? 'bg-gray-800 hover:bg-gray-750' : 'bg-white hover:bg-pink-50'}`}>
                            <div className={`h-12 w-12 mx-auto rounded-lg flex items-center justify-center mb-4 
                                ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-pink-500 to-rose-500'}`}>
                                <benefit.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Learning Types Section */}
            <div className="max-w-7xl mx-auto px-4 py-16">
                <div className={`rounded-2xl p-8 ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl`}>
                    <h2 className={`text-3xl md:text-4xl font-bold mb-12 text-center ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        Unterstützung aller Lerntypen
                    </h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        {learningTypes.map((type, idx) => (
                            <div key={idx} className={`p-6 rounded-xl ${darkMode ? 'bg-gray-700' : 'bg-pink-50'}`}>
                                <h3 className={`text-xl font-bold mb-4 text-center ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                    {type.title}
                                </h3>
                                <div className="space-y-3">
                                    {type.content.map((item, itemIdx) => (
                                        <div key={itemIdx} className={`flex items-start gap-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                            <CheckCircle className="h-5 w-5 text-pink-500 flex-shrink-0 mt-1" />
                                            <p>{item}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Statistics Section */}
            <div className="max-w-7xl mx-auto px-4 py-16">
                <div className="grid md:grid-cols-3 gap-8">
                    {[
                        { number: "85%", text: "der Schüler*innen lernen erfolgreicher im eigenen Tempo" },
                        { number: "3x", text: "höhere Motivation durch personalisierte Lernziele" },
                        { number: "92%", text: "besseres Verständnis durch multimediale Lerninhalte" }
                    ].map((stat, idx) => (
                        <div key={idx} className={`p-6 text-center rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
                            <div className={`text-4xl font-bold mb-2 ${darkMode ? 'text-pink-400' : 'text-pink-500'}`}>
                                {stat.number}
                            </div>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{stat.text}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <div className={`rounded-2xl p-8 ${darkMode ? 'bg-gray-800' : 'bg-gradient-to-r from-pink-500 to-rose-500'}`}>
                    <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-white'}`}>
                        Starten Sie mit personalisiertem Lernen
                    </h2>
                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-white opacity-90'}`}>
                        Entdecken Sie, wie Sie mit digitalen Tools jeden Schüler individuell
                        fördern und zum Erfolg führen können.
                    </p>
                    <button className={`inline-flex items-center px-8 py-4 bg-white text-pink-600 rounded-xl 
                        hover:bg-pink-50 transition-all transform hover:scale-105 shadow-lg
                        ${darkMode ? 'bg-pink-500 text-white hover:bg-pink-600' : ''}`}>
                        Jetzt loslegen <Rocket className="h-5 w-5 ml-2"/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IndividualLearningPages;