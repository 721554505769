import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importiere useNavigate
import { Palette, Sparkles, ArrowLeft, Puzzle, Lightbulb, Users, Share2, Wrench, Rocket } from 'lucide-react';

const CreativeProjectsPage = () => {
    const [darkMode, setDarkMode] = useState(false);
    const navigate = useNavigate(); // Initialisiere useNavigate

    const projectFeatures = [
        {
            icon: Palette,
            title: "Kreative Freiheit",
            description: "Nutze verschiedene digitale Werkzeuge, um deine Ideen genau so umzusetzen, wie du sie dir vorstellst."
        },
        {
            icon: Puzzle,
            title: "Projektbasiertes Lernen",
            description: "Entwickle praktische Lösungen für reale Herausforderungen und lerne dabei spielerisch neue Fähigkeiten."
        },
        {
            icon: Users,
            title: "Teamarbeit",
            description: "Arbeite mit anderen zusammen, tausche Ideen aus und lerne von den Perspektiven deiner Mitschüler."
        },
        {
            icon: Wrench,
            title: "Digitale Werkzeuge",
            description: "Entdecke moderne Tools für Bildbearbeitung, 3D-Design, Videobearbeitung und vieles mehr."
        },
        {
            icon: Share2,
            title: "Präsentation & Feedback",
            description: "Stelle deine Projekte vor und erhalte wertvolles Feedback für deine Weiterentwicklung."
        },
        {
            icon: Lightbulb,
            title: "Innovation fördern",
            description: "Entwickle eigene Lösungsansätze und stärke dein kreatives Denken durch praktische Erfahrungen."
        }
    ];

    const subjectExamples = [
        {
            subject: "Mathematik",
            examples: [
                "Geometrische Formen mit GeoGebra visualisieren",
                "Mathematische Konzepte durch interaktive Simulationen erklären",
                "Statistik-Projekte mit Echtzeitdaten durchführen"
            ]
        },
        {
            subject: "Deutsch",
            examples: [
                "Gemeinsames Schreiben kreativer Geschichten",
                "Digitale Buchpräsentationen erstellen",
                "Podcasts zu Literaturthemen aufnehmen"
            ]
        },
        {
            subject: "Naturwissenschaften",
            examples: [
                "Experimente mit der Tablet-Kamera dokumentieren",
                "Naturphänomene mit AR-Apps erkunden",
                "Digitale Versuchsprotokolle führen"
            ]
        },
        {
            subject: "Kunst",
            examples: [
                "Digitale Kunstwerke erstellen",
                "Stop-Motion Animationen produzieren",
                "Virtuelle Kunstgalerien gestalten"
            ]
        },
        {
            subject: "Sprachen",
            examples: [
                "Vokabeltraining mit Lern-Apps",
                "Videokonferenzen mit Partnerschulen",
                "Digitale Sprachportfolios erstellen"
            ]
        },
        {
            subject: "Geschichte",
            examples: [
                "Interaktive Zeitstrahlen erstellen",
                "Historische Orte virtuell erkunden",
                "Digitale Zeitzeugeninterviews durchführen"
            ]
        }
    ];

    return (
        <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
            {/* Navigation Button */}
            <button
                onClick={() => navigate(-1)} // Verwende navigate, um zur vorherigen Seite zu gelangen
                className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-purple-500 to-violet-500 text-white rounded-lg hover:from-purple-600 hover:to-violet-600 transition-all transform hover:scale-105 shadow-md z-50"
            >
                <ArrowLeft className="h-5 w-5" />
                <span>Zurück</span>
            </button>

            {/* Header Section */}
            <div className="relative overflow-hidden">
                <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-r from-gray-800 to-gray-900' : 'bg-gradient-to-r from-purple-500 to-violet-500'} opacity-10 transform -skew-y-6`}></div>
                <div className="relative max-w-7xl mx-auto px-4 py-20 text-center">
                    <div className={`inline-flex items-center gap-2 px-4 py-2 rounded-full mb-8 animate-bounce ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-purple-100 text-purple-600'}`}>
                        <Sparkles className="h-5 w-5"/>
                        <span className="font-medium">Entfessle deine Kreativität</span>
                    </div>

                    <h1 className={`text-4xl md:text-5xl font-bold mb-6 tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                        <span className={`${darkMode ? 'bg-gradient-to-r from-gray-300 to-gray-400' : 'bg-gradient-to-r from-purple-500 to-violet-500'} text-transparent bg-clip-text`}>
                            Kreative Projektarbeit mit digitalen Tools
                        </span>
                    </h1>

                    <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Verwandle deine Ideen in beeindruckende Projekte. Mit modernsten digitalen Werkzeugen und kreativen Methoden machst du deine Visionen zur Realität.
                    </p>
                </div>
            </div>

            {/* Features Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-8 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Entdecke die Möglichkeiten
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {projectFeatures.map((feature, idx) => (
                        <div key={idx} className={`p-6 rounded-xl hover:shadow-xl transition-all ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-purple-50'}`}>
                            <div className={`h-12 w-12 mx-auto rounded-lg flex items-center justify-center mb-4 ${darkMode ? 'bg-gray-700' : 'bg-gradient-to-br from-purple-500 to-violet-500'}`}>
                                <feature.icon className="h-6 w-6 text-white"/>
                            </div>
                            <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Subject Examples Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 relative">
                <div className={`absolute inset-0 bg-gradient-to-b ${darkMode ? 'from-gray-800 to-gray-900' : 'from-purple-100 to-white'} opacity-50 transform -skew-y-3`}></div>
                <div className="relative">
                    <div className="text-center mb-16">
                        <div className={`inline-flex items-center gap-2 px-6 py-2 rounded-full mb-4 ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
                            <Sparkles className={`h-5 w-5 ${darkMode ? 'text-purple-400' : 'text-purple-600'}`} />
                            <span className={`font-medium ${darkMode ? 'text-purple-400' : 'text-purple-600'}`}>Zukunftsorientierter Unterricht</span>
                        </div>
                        <h2 className={`text-4xl font-bold mb-4 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                            Praktische Beispiele für Ihren Unterricht
                        </h2>
                        <p className={`text-xl max-w-2xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            Entdecken Sie innovative Möglichkeiten, Ihren Unterricht mit digitalen Tools zu bereichern
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {subjectExamples.map((subject, idx) => (
                            <div key={idx}
                                 className={`group relative overflow-hidden rounded-2xl transition-all duration-300 transform hover:scale-105 hover:shadow-2xl
                                ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-purple-50'}`}
                            >
                                <div className={`absolute top-0 left-0 w-2 h-full bg-gradient-to-b from-purple-500 to-violet-500 transition-all duration-300 group-hover:w-full group-hover:opacity-10`}></div>
                                <div className="p-8 relative">
                                    <h3 className={`text-2xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'} 
                                        bg-gradient-to-r from-purple-500 to-violet-500 bg-clip-text text-transparent`}>
                                        {subject.subject}
                                    </h3>
                                    <ul className={`space-y-4 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                        {subject.examples.map((example, exIdx) => (
                                            <li key={exIdx} className="flex items-start group/item hover:text-purple-600 transition-colors duration-200">
                                                <span className="inline-block mt-1.5 mr-3 w-1.5 h-1.5 rounded-full bg-purple-500 group-hover/item:scale-125 transition-transform duration-200"></span>
                                                <span>{example}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="max-w-7xl mx-auto px-4 py-16 text-center">
                <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    Bereit, deine Projekte zu starten?
                </h2>
                <p className={`text-xl max-w-3xl mx-auto mb-8 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Egal ob Anfänger oder Fortgeschrittener - unsere Tools und Ressourcen helfen dir dabei, deine kreativen Ideen zum Leben zu erwecken.
                </p>
                <button className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-purple-500 to-violet-500 text-white rounded-xl hover:from-purple-600 hover:to-violet-600 transition-all transform hover:scale-105 shadow-lg">
                    Projekt starten <Rocket className="h-5 w-5 ml-2"/>
                </button>
            </div>

            {/* Dark Mode Toggle */}
            <button
                onClick={() => setDarkMode(!darkMode)}
                className="fixed bottom-4 right-4 p-3 rounded-full bg-gradient-to-r from-purple-500 to-violet-500 text-white shadow-lg hover:shadow-xl transition-all"
            >
                {darkMode ? '☀️' : '🌙'}
            </button>
        </div>
    );
};

export default CreativeProjectsPage;
